import Head from "next/head";

interface HeadProps {
  title: string;
}

export default function HeadTitleKeyword({ title }: HeadProps) {
  return (
    <Head>
      <title>
        {title ?? "Webshare Buy Proxy Servers | Fast &amp; Affordable "}
      </title>
      <meta
        name="keywords"
        content="proxy, shared proxy, dedicated proxy, proxy server, proxy service, free proxy, proxy list"
      />
    </Head>
  );
}
