import { styled, Theme, Typography, TypographyProps } from "@mui/material";
import { JSXElementConstructor, ReactElement } from "react";

export type HighlighterSeverity =
  | "error"
  | "warning"
  | "info"
  | "primary"
  | "success"
  | "secondary"
  | "default";

export type HighlighterSize = "medium" | "small";

export type HighlighterVariant = "filled" | "outlined" | "opacity";

interface TypographyPropsWithIcon extends TypographyProps {
  icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
}

interface HighlighterProps extends TypographyPropsWithIcon {
  severity: HighlighterSeverity;
  size: HighlighterSize;
  innerVariant: HighlighterVariant;
}

function resolveHighlighterColor(
  theme: Theme,
  severity: HighlighterSeverity,
  innerVariant: HighlighterVariant
) {
  const isDarkMode = theme.palette.mode === "dark";

  if (severity === "default") {
    return isDarkMode ? theme.palette.common.white : theme.palette.common.black;
  }

  if (innerVariant === "filled") {
    return isDarkMode ? theme.palette.common.black : theme.palette.common.white;
  }

  return theme.palette[severity].main;
}

/**
 * Since we want to extend the Typography component, we need to use the styled
 * function from MUI.
 * Because of that we use the before pseudo element to create the highlight
 * effect.
 * Because of that we need to set specific padding and border radius to make
 * sure the highlight effect is visible and matches the design.
 */
const PADDING = "7px 2px 4px 2px";
const SMALL_PADDING = "3px 2px 3px 2px";
const TEXT_PADDING = "3px 4px 0px 4px";
const SMALL_TEXT_PADDING = "0px 4px 0px 2px";
const BORDER_RADIUS = "8px";
export const Highlighter = styled(
  (props: TypographyPropsWithIcon) => (
    <Typography {...props} component="span">
      {props.icon}
      <span>{props.children}</span>
    </Typography>
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== "color" && prop !== "variant" && prop !== "innerVariant",
  }
)<HighlighterProps>(
  ({ innerVariant, severity = "primary", theme, size = "medium" }) => ({
    "&::before": {
      opacity:
        innerVariant === "opacity"
          ? 0.12
          : severity === "default" && theme.palette.mode === "dark"
            ? 0.16
            : 1,
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      backgroundColor:
        innerVariant === "outlined"
          ? "transparent"
          : severity === "default"
            ? {
                filled:
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.grey[200],
                opacity:
                  theme.palette.mode === "dark"
                    ? theme.palette.common.white
                    : theme.palette.grey[500],
              }[innerVariant]
            : theme.palette[severity].main,
      zIndex: 1,
      borderRadius: BORDER_RADIUS,
      border: `1px solid ${
        severity === "default"
          ? theme.palette.mode === "dark"
            ? theme.palette.grey[700]
            : theme.palette.grey[500]
          : {
              filled: theme.palette[severity].dark,
              outlined: theme.palette[severity].light,
              opacity: theme.palette[severity].main,
            }[innerVariant]
      }`,
      height: "100%",
    },
    // this is the text
    span: {
      position: "relative",
      padding: size === "medium" ? TEXT_PADDING : SMALL_TEXT_PADDING,
      zIndex: 2,
    },
    svg: {
      position: "relative",
      zIndex: 2,
      width: "14px",
      height: "14px",
      display: "inline-block",
      float: "left",
      fill: resolveHighlighterColor(theme, severity, innerVariant),
    },
    img: {
      position: "relative",
      zIndex: 2,
      width: "14px",
      height: "14px",
      display: "inline-block",
      float: "left",
      fill: resolveHighlighterColor(theme, severity, innerVariant),
      marginRight: 3,
    },
    borderRadius: BORDER_RADIUS,
    position: "relative",
    backgroundColor: "transparent",
    fontWeight: 600,
    padding: size === "medium" ? PADDING : SMALL_PADDING,
    fontSize: size === "medium" ? "11px" : "10px",
    color: resolveHighlighterColor(theme, severity, innerVariant),
    display: "inline-block",
    textTransform: "uppercase",
  })
);
