import { isEmpty } from "lodash";

export function commaAndFormatStringList(texts: string[]) {
  if (texts.length == 0) {
    return "";
  }
  if (texts.length == 1) {
    return texts[0];
  }
  const last = texts.pop();
  return texts.join(", ") + " and " + last;
}

export function toLocaleString(amount: number = 0): string {
  return amount.toLocaleString();
}
export function userFormatCurrency(amount: number = 0): string {
  return Number(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function userReadableBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

//-{country_code}-{N} part. We should only lowercase the {country_code} part.
//Lower case only countryCodes.
export function lowerCaseSpecial(text: string) {
  if (typeof text === "string") {
    const arr = text.split(/-(.*)/s);
    if (arr.length > 1) {
      return `${arr[0]}-${arr[1].toLowerCase()}`;
    }
  }

  return text;
}

export const kebabCase = (text: string) =>
  text
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();

export function capitalizeFirstLetter(string: string) {
  if (string.charAt(0)) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export function capitalizeFirstLetterOfEachWord(string: string) {
  return string.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string) {
    return word.toUpperCase();
  });
}

export function humanize(str: string) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function getQueryParamValue(value: string | null | undefined) {
  if (isEmpty(value)) {
    return undefined;
  } else {
    return value;
  }
}

export function joinWithCommaEndWithAnd(parts: string[]) {
  return parts.reduce(
    (acc: string, part: string, index: number, array: string[]) => {
      const isLast = index === array.length - 1;
      const isFirst = index === 0;
      if (isFirst) {
        return part;
      }
      if (isLast) {
        return `${acc} and ${part}`;
      }
      return `${acc}, ${part}`;
    },
    ""
  );
}

type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ""
    ? []
    : S extends `${infer T}${D}${infer U}`
      ? [T, ...Split<U, D>]
      : [S];

export function typedSplit<T extends string, S extends string>(str: T, sep: S) {
  return str.split(sep) as Split<T, S>;
}
