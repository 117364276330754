import { z } from "zod";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { ParsedUrlQuery } from "querystring";

/**
 * zod schema
 * --------------
 * zod keeps us safe from typos in the url bar.
 * keys are the query param keys
 * values are the zod schemas for the values
 *
 * Why are types stored in this file and not defined in pages or components?
 * --------------
 * The URL is a globally accessible to the app, therefore they should be
 * defined in a single place: here.
 */
const OptionSchema = z.object({
  id: z.string(),
  label: z.string(),
  subtitle: z.string(),
  available: z.number(),
});
const SingleOption = OptionSchema.optional();
const SingleOrPluralMultiSelectSchema = z
  .union([OptionSchema, z.array(OptionSchema)])
  .optional();
const UrlSchema = z.object({
  // Replace Proxy Page
  // ---------
  mode: z.string().optional(),
  addType: z
    .union([
      z.literal("any"),
      z.literal("country"),
      z.literal("asn"),
      z.literal("ip_range"),
    ])
    .optional(),
  removeType: z.union([
    z.literal("any"),
    z.literal("country"),
    z.literal("asn"),
    z.literal("ip_range"),
  ]),
  replaceProxyStep: z.union([z.literal(0), z.literal(1), z.literal(2)]),
  removeAsn: SingleOption,
  removeCountry: SingleOption,
  removeRange: SingleOption,
  verificationCategory: z.string(),
  removeIpsText: z.string().optional(),
  addAsns: SingleOrPluralMultiSelectSchema,
  addCountries: SingleOrPluralMultiSelectSchema,
  addRanges: SingleOrPluralMultiSelectSchema,
  // Activity Page
  // ---------
  activityTimestampLte: z.string().datetime({ precision: 3 }).optional(), // "2020-01-01T00:00:00.123Z"
  activityTimestampGte: z.string().datetime({ precision: 3 }).optional(), // "2020-01-01T00:00:00.123Z"
  // List Page
  // ---------
  authenticationMethod: z
    .union([z.literal("username_password"), z.literal("ip")])
    .optional(),
  connectionMethod: z
    .union([z.literal("direct"), z.literal("backbone"), z.literal("rotating")])
    .optional(),
  proxyControl: z.string().optional(),
  filterByCountryOpen: z.boolean().optional(),
  exampleCodeOpen: z.boolean().optional(),
  // Customize
  // ---------
  isCustomPlan: z.boolean().optional(),
  // customPlanType: z.union([z.literal("free")]),
  customPlanType: z.literal("verified_custom").optional(),
  proxyType: z
    .union([
      z.literal("free"),
      z.literal("shared"),
      z.literal("semidedicated"),
      z.literal("dedicated"),
    ])
    .optional()
    .default("shared"),
  proxySubtype: z
    .union([
      z.literal("default"),
      z.literal("non-premium"),
      z.literal("premium"),
      z.literal("isp"),
      z.literal("residential"),
      z.literal("verified"),
      z.literal("verifiedIsp"),
    ])
    .default("default"),

  proxyCount: z.number().default(100),
  customProxyCount: z.number().default(0),
  autoRefreshFrequency: z.number().default(0),
  isCustomAutoRefreshFrequency: z.boolean().default(false),
  isUnlimitedIpAuthorizations: z.boolean().default(false),
  isHighConcurrency: z.boolean().default(false),
  isHighPriorityNetwork: z.boolean().default(false),
  isYearly: z.boolean().default(false),
  is2xProxies: z.boolean().default(false),

  isCustomSubuserCount: z.boolean().default(false),
  isCustomOnDemandRefreshes: z.boolean().default(false),
  proxyReplacements: z.number().default(10),
  isCustomProxyReplacements: z.boolean().default(false),
  onDemandRefreshes: z.number().default(0),
  subuserCount: z.number().default(3),
  customSubuserCount: z.number().default(0),
  bandwidth: z.number().default(5000),
  siteChecks: z.string().array().default([]),
  rowsPerPage: z.number().optional(),
  page: z.number().optional(),
  order: z.union([z.literal("asc"), z.literal("desc")]).optional(),
  orderBy: z.string().nullable().optional(),
  searchValue: z.string().optional(),
  filters: z
    .object({
      id: z.union([z.undefined(), z.string(), z.number()]),
      field: z.string(),
      value: z.any(),
      operator: z.string(),
    })
    .array()
    .optional(),
  customAutomaticRefreshFrequencyPeriod: z
    .union([z.literal("h"), z.literal("min"), z.literal("w"), z.literal("mo")])
    .default("h"),
  countries: z
    .record(z.string(), z.union([z.number(), z.undefined(), z.literal("")]))
    .default({}),
  // Express Checkout
  showPlanDetails: z.boolean().optional(),
  // Welcome Modal
  // ---------
  showWelcomeDialog: z.boolean().optional(),
  source: z.literal("welcome-dialog").optional(),
  // Subscription Page
  openPaymentMethod: z.boolean().optional(),
});

export type UrlVal = z.infer<typeof UrlSchema>;
export type UrlValOption = z.infer<typeof OptionSchema>;
export type SetParam<T> = (value: T) => void;

function zodParse(key: keyof UrlVal, value: any) {
  return UrlSchema.shape[key].parse(value);
}

/**
 * useDeleteQueryParam
 * --------------
 * Deletes many query params at once
 *
 * This function takes an array of keys and deletes all of those query params
 * from the URL in one go. Use this when you want to clear a bunch of query
 * params at once because nextjs has dodgy support for rapidly removing multiple
 * query params sequentially.
 *
 * @example
 * const deleteQueryParams = useDeleteQueryParams();
 * deleteQueryParams(["mode", "addType", "removeType"]);
 */
export function useDeleteQueryParams() {
  const { query, pathname, push } = useRouter();

  // Takes an array of keys to delete from the url
  return useCallback(
    (keys: (keyof UrlVal)[]) => {
      keys.forEach((key) => {
        delete query[key];
      });
      const newPath = { pathname, query };
      push(newPath, undefined, { shallow: true });
    },
    [push, pathname, query]
  );
}

// Setter, this sets the key=value pair in the url
function useSetQueryParam<T>(
  parameterKey: keyof UrlVal,
  method: "replace" | "push"
) {
  const { query, pathname, push, replace } = useRouter();

  return useCallback(
    (value: T) => {
      try {
        zodParse(parameterKey, value);
      } catch (e) {
        throw new Error(`Invalid value for '${parameterKey}': ${e}`);
      }

      // Convert the value to a string
      const paramValue = JSON.stringify(value);

      // IMPORTANT: we're updating the query object directly here as next router cannot batch updates
      // This helps us to make multiple updates to the query without lossing any of the data
      query[parameterKey] = paramValue;

      // Store that value in the URL
      const newPath = { pathname, query };
      method === "replace"
        ? replace(newPath, undefined, { shallow: true })
        : push(newPath, undefined, { shallow: true });
    },
    [query, parameterKey, pathname, method, replace, push]
  );
}

/**
 * Initializer (optional)
 * --------------
 * This sets the initial value of the key=value pair in the url if one is
 * provided.
 *
 * const [addType, setAddType] = useUrlState("addType", "any");
 *
 * In this case "any" would be set in the URL when the page loads if there is no
 * existing value in the URL.
 */
function useInitialQueryParam<T>(
  setParam: SetParam<T>,
  initialValue: T | undefined,
  query: ParsedUrlQuery,
  parameterKey: keyof UrlVal
) {
  useEffect(() => {
    if (query[parameterKey] !== undefined || initialValue === undefined) {
      return;
    }
    // Validate initial value with Zod
    try {
      zodParse(parameterKey, initialValue);
    } catch (e) {
      throw new Error(`Invalid value for '${parameterKey}': ${e}`);
    }

    setParam(initialValue);
  }, [setParam, initialValue, query, parameterKey]);
}

function parseValue<K extends keyof UrlVal>(
  key: K,
  query: ParsedUrlQuery,
  initialValue?: UrlVal[K],
  setParam?: SetParam<UrlVal[K]>
) {
  let parsedValue: UrlVal[K] | undefined;
  const value = query[key];

  if (value) {
    let parsed: ReturnType<typeof JSON.parse>;

    // JSON.parse the value from the URL
    try {
      parsed = JSON.parse(value as string);
    } catch (_) {
      parsed = undefined;
    }

    // Zod parse
    try {
      parsedValue = zodParse(key, parsed) as UrlVal[K];
      if (setParam && parsed === undefined) {
        setParam(parsedValue);
      }
    } catch (e) {
      parsedValue = zodParse(key, undefined) as UrlVal[K];
      if (setParam && parsedValue) {
        setParam(parsedValue);
      } else {
        throw new Error(
          `Did the user edit the URL? Could not zodParse '${key}': ${e}`
        );
      }
    }
  } else if (initialValue !== undefined) {
    // Optimistically return the initial value if there is no value in the URL
    try {
      // Validate initial value with Zod
      parsedValue = zodParse(key, initialValue) as UrlVal[K];
    } catch (_) {
      // We don't throw an error here because useInitialQueryParam will throw
    }
  }
  return parsedValue;
}

/**
 * useUrlState
 * --------------
 * const [addType, setAddType] = useUrlState("addType");
 * addType is the value of the key=value pair in the url
 * setAddType is a function that sets the value of the key=value pair in the url
 *
 * IMPORTANT:
 * The value returned from this hook is validated with Zod. If the value in the
 * url is invalid, an error will be thrown.
 */

export default function useUrlState<K extends keyof UrlVal>(
  key: K,
  initialValue?: UrlVal[K],
  method: "push" | "replace" = "push"
): [UrlVal[K] | undefined, SetParam<UrlVal[K]>] {
  const { query } = useRouter();
  const setParam = useSetQueryParam<UrlVal[K]>(key, method);

  // Initialize parameter with the initial value
  useInitialQueryParam(setParam, initialValue, query, key);
  const parsedValue = parseValue(key, query, initialValue, setParam);
  return [parsedValue, setParam];
}

/**
 * useBatchUrlState
 * --------------
  const [values, setParams] = useBatchUrlState([
    ["addType", "any"],
    ["removeType", "country"],
  ])
 * 
 * setParams will update the key value pairs set from the batch update
 *
  setParams([
    ["addType", "any"],
    ["removeType", "country"],
  ])
 */

export function useBatchUrlState<K extends keyof UrlVal>(
  keyValuePairs: [key: K, value?: UrlVal[K]][]
): [(UrlVal[K] | undefined)[], SetParam<[K, UrlVal[K]][]>] {
  const { query, pathname, push } = useRouter();

  const setParams = useCallback(
    (keyValuePairs: [key: K, value?: UrlVal[K]][]) => {
      const updateQuery = keyValuePairs.reduce((acc, [key, value]) => {
        try {
          zodParse(key, value);
          Object.assign(acc, { [key]: JSON.stringify(value) });
          return acc;
        } catch (e) {
          throw new Error(`Invalid value for '${key}': ${e}`);
        }
      }, {});

      const newPath = { pathname, query: { ...query, ...updateQuery } };
      push(newPath, undefined, { shallow: true });
    },
    [pathname, push, query]
  );

  useEffect(() => {
    const initialParams = keyValuePairs.filter(([key, value]) => {
      return query[key] === undefined && value !== undefined;
    });
    initialParams.length && setParams(initialParams);
  }, [keyValuePairs, query, setParams]);

  const parsedValues = keyValuePairs.map(([key, value]) =>
    parseValue(key, query, value)
  );

  return [parsedValues, setParams];
}

/**
 * Takes an object, and returns a new object where all values were run through JSON.stringify.
 *
 * Useful when you want to push/replace a new query object to the URL. eg
 * router.push({ pathname: "/path", query: prepareObjectForQuery({ key: [1,2,3] }) })
 */
export function prepareObjectForQuery(
  obj: Partial<UrlVal>
): Record<string, string> {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      return { ...acc, [key]: JSON.stringify(value) };
    },
    {} as Record<string, string>
  );
}
