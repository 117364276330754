/* eslint-disable @next/next/no-img-element */
import { CardProps, styled, Card } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export interface ProxyTypeCardProps {
  selected: boolean;
  tick?: boolean;
  inverted?: boolean;
}

export const CardWithIndicator = styled(Card, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{
  selected: boolean;
}>(({ theme, selected }) => ({
  width: "30px",
  height: "30px",
  cursor: selected ? "none" : "pointer",
  borderRadius: "8px",
  position: "relative",
  "&::before,&::after": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    borderColor: selected ? theme.palette.primary.main : "transparent",
    borderStyle: "solid",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  "&::before": {
    borderWidth: "13px",
    borderRightColor: selected ? theme.palette.primary.main : "transparent",
    borderTopColor: selected ? theme.palette.primary.main : "transparent",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "0px",
  },
}));

export function ProxyTypeCard(props: ProxyTypeCardProps & CardProps) {
  const { tick, inverted, ...rest } = props;
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  const colorStyles =
    inverted && !isLightMode
      ? {
          backgroundImage: "none",
          boxShadow: 0,
          bgcolor: props.selected
            ? theme.palette.grey[100]
            : theme.palette.grey[50],
          borderColor: undefined,
          border: "0px",
          "&:hover": {
            bgcolor: theme.palette.grey[100],
          },
        }
      : {
          boxShadow: props.selected && isLightMode ? theme.shadows[2] : 0,
          bgcolor: props.selected
            ? isLightMode
              ? theme.palette.background.paper
              : theme.palette.grey[100]
            : theme.palette.grey[50],

          border: isLightMode ? "1px solid" : "0px",
          borderColor: isLightMode
            ? props.selected
              ? grey[200]
              : grey[300]
            : undefined,
          "&:hover": {
            bgcolor: props.selected
              ? undefined
              : isLightMode
                ? theme.palette.grey[50]
                : theme.palette.grey[100],
            borderColor: props.selected
              ? undefined
              : isLightMode
                ? theme.palette.grey[600]
                : theme.palette.grey[100],
          },
        };

  return tick ? (
    <CardWithIndicator
      {...rest}
      sx={[colorStyles, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
    >
      <CheckOutlinedIcon
        sx={{
          fontSize: "16px",
          position: "absolute",
          right: 5,
          top: 5,
          color: props.selected
            ? isLightMode
              ? "white"
              : "primary.contrastText"
            : "transparent",
        }}
      />
      {props.children}
    </CardWithIndicator>
  ) : (
    <Card
      {...rest}
      sx={[
        colorStyles,
        { p: 2 },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {props.children}
    </Card>
  );
}
